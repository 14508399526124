<div 
    style="height: 100vh; width: 100vw; background-color: #adadff;"
    fxLayout="column" fxLayoutAlign="center center">

    <div fxLayout="column" fxLayoutGap="16px" style="padding: 24px; background-color: white;">

      <button mat-raised-button color="primary" (click) = "goHome()">Home</button>

        <span *ngIf = "match" ng-hide ="editing">
          <button (click)= "NewName.enable()">Edit Name</button>
        </span>

        <span ng-show="editing">

          <mat-form-field appearance="standard" style="width: 250px;">
            <!-- <mat-label>Username</mat-label> -->
            <input matInput placeholder="{{Name}}" [formControl]="NewName" required>
          </mat-form-field>

          <button *ngIf = "match" (click)="NewName.disable(); save(NewName.value,'userName')">Save</button>
        </span>

        <!-- EMAIL -->
        <span *ngIf = "match" ng-hide="editing">
          <button (click)="NewEmail.enable()">Edit email</button>
        </span>

        <span ng-show="editing">
          <mat-form-field appearance="standard" style="width: 250px;">
            <mat-label>{{email}}</mat-label>
            <input matInput [formControl]="NewEmail" required>
          </mat-form-field>

          <button *ngIf = "match" (click)="NewEmail.disable(); save(NewEmail.value,'email')">Save</button>
        </span>
    </div>
</div>

