<div fxLayout="column" fxLayoutAlign="space-between" style="height: 100%; width: 100%;">
    <div fxLayout="column" fxLayoutGap="16px">
        <!--Icons for switching between groups/friends-->
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <mat-icon class="cursor-pointer" (click)="changeFriendsView(true)">person</mat-icon>
            <mat-icon class="cursor-pointer" (click)="changeFriendsView(false)">groups</mat-icon>
        </div>

        <mat-divider></mat-divider>

        <!--Scrollable list of groups/friends-->
        <div fxLayout="column" fxLayoutGap="16px" style="overflow: scroll">
            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-icon 
                    *ngIf="showingFriends"
                    class="cursor-pointer add-button"
                    (click)="addChat()"
                    fxLayout="row"
                    fxLayoutAlign="center center">person_add</mat-icon>
                <mat-icon
                    *ngIf="!showingFriends"
                    class="cursor-pointer add-button"
                    (click)="addChat()"
                    fxLayout="row"
                    fxLayoutAlign="center center">group_add</mat-icon>
            </div>
            <!--iterate through the list here-->
            <div *ngIf="showingFriends" fxLayout="column" fxLayoutGap="16px">
                <div *ngFor="let chat of friendChats" (click)="handleChangeChat(chat)"
                     fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <img
                        src="https://i.imgur.com/k2PZLZa.png"
                        alt="Group avatar"
                        class="cursor-pointer"
                        style="height: 36px; border-radius: 18px;"
                    />
                    <div class="cursor-pointer truncate">{{chat.name}}</div>
                </div>
            </div>
            <div *ngIf="!showingFriends" fxLayout="column" fxLayoutGap="16px">
                <div *ngFor="let chat of groupChats" (click)="handleChangeChat(chat)"
                     fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <img
                        src="https://i.imgur.com/k2PZLZa.png"
                        alt="Group avatar"
                        class="cursor-pointer"
                        style="height: 36px; border-radius: 18px;"
                    />
                    <div class="cursor-pointer truncate">{{chat.title}}</div>
                </div>
            </div>
        </div>
    </div>
    <!--Your profile-->
    <div fxLayout="column" fxLayoutGap="16px">
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayoutGap="16px" style="height: 42px;">
            <img
                src="https://i.imgur.com/k2PZLZa.png"
                alt="User avatar"
                class="cursor-pointer"
                style="height: 36px; border-radius: 18px;"
                (click) = "goToUserPage()"
            />
            <div
                class="cursor-pointer"
                (click) = "goToUserPage()">{{currentUserName}}</div>
        </div>
    </div>
</div>