<div fxLayout="row" style="height: inherit;">
    <!--Expand/Collapse Button (change to chevron_right if open)-->
    <div fxLayout="column" 
         fxLayoutAlign="center center"
        class="cursor-pointer"
        style="height: inherit; background-color: grey;"
        (click)="expandToggle()">
        <mat-icon *ngIf="!expanded">chevron_left</mat-icon>
        <mat-icon *ngIf="expanded">chevron_right</mat-icon>
    </div>
    <!-- Code Lists -->
    <div class="code-list"
         style="height: inherit; overflow-y: scroll"
         [class.hiddenn]="expanded"
         fxLayout="column"
         fxLayoutGap="24px"> 

        <div class="side-bar">
            <div class="sec-widget" 
            id="idInHTML"
                data-id="A"
                data-widget="a9db7e9dfcb9c92c4908b96b62fa74bc">
            </div>
        </div>
        <div class="side-bar">
            <div class="sec-widget" 
            id="idInHTML"
                data-id="B"
                data-widget="a96f42e9705277dafb7f0113f5ea39b6">
            </div>
        </div>
        <div class="side-bar">
            <div class="sec-widget" 
            id="idInHTML"
                data-id="C"
                data-widget="541bf9a9b4a65ba9cc9ab0d3246c5a66">
            </div>
        </div>
    </div>

</div>
