<div *ngIf="chatID" class="mesgs" fxLayout="column" fxLayoutAlign="start start">
    <div class="msg_history">
        <li class="message" *ngFor="let message of messages">
            <div *ngIf="message.userID !== userID" fxLayout="row" fxLayoutGap="6px">
                <img
                    src="https://i.imgur.com/k2PZLZa.png"
                    alt="User avatar"
                    style="width: 36px; height: 36px; border-radius: 18px;"
                />
                <div class="received_msg">
                    <div class="received_withd_msg">
                        <div style="font-size: x-small;">
                            {{message.userName}}</div>
                        <p>{{ message.text }}</p>
                    </div>
                </div>
            </div>
            
            <div *ngIf="message.userID === userID" class="outgoing_msg">
                <div class="sent_msg">
                <p>{{ message.text }}</p>
                </div>
            </div>
        </li>
    </div>
    
    <div class="type_msg">
      <form class="input_msg_write">
        <input
          type="text"
          class="write_msg"
          placeholder="Type a message"
          name="newMessage"
          [(ngModel)]="newMessage"
          style="padding-left: 6px;"
          (keydown.enter)="sendMessage()"
        />
        <button class="msg_send_btn" type="button" (click)="sendMessage()">
            <mat-icon style="margin-top: 3px;">send</mat-icon>
        </button>
      </form>
    </div>
</div>

<div *ngIf="!chatID" fxLayout="row" fxLayoutAlign="center center" style="height: 100%; width: 100%;">
    <div style="font-size: 36px;">
        Start Chatting!
    </div>
</div>