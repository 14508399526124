<div fxLayout="row" style="min-width: 600px; width: 100%; height: 100%;">
    <!--Left Sidebar-->
    <app-chats 
        style="max-width: 200px !important; min-width: 200px !important;
            height: calc(100% - 31px); padding: 19px 12px 12px; background-color: #adadff;"
        (changeChatEvent)="changeChat($event)"></app-chats>
    <div fxLayout="column" fxLayoutAlign="space-between start"
         style="height: 100%; min-width: 0px; flex-grow: 1;">
        <!--Top Sidebar-->
        <app-info-bar
            style="width: 100%; background-color: lightgray; padding: 12px 0px 0px;"
            [chatID]="activeChatId"></app-info-bar>
        <!--Messaging Part-->
        <app-messaging style="width: 100%; min-height: 0px; flex-grow: 1; padding: 0px 0px 12px;"
                       [userID]="userID"
                       [chatID]="activeChatId"></app-messaging>
    </div>
    <!--Right Sidebar, Codes (can expand/collapse)-->
    <app-codes-list style="max-width: 600px; height: 100%;"
                    [chatID]="activeChatId"
                    [hidden]="!activeChatId"></app-codes-list>
</div>
